// @ts-nocheck
import React,{useState,useEffect} from 'react'

import "./index.scss"

export default function CookieBanner() {
    const [state, setstate] = useState(localStorage.getItem("acceptCookie"))
    const [info, setinfo] = useState(null);
    useEffect(() => {
        async function getMoviesFromApi() {
          try {
            let response = await fetch(
              "https://play.leerecs.com/api/content"
            );
            let responseJson = await response.json();
            // console.log("the info", responseJson);
            setinfo(responseJson.data);

            setloaded(true)
          } catch (error) {
            console.error(error);
          }
        }
        
          getMoviesFromApi();
        
       
      }, []);
    return (
        <div>
            {(!(state) &&info&& (<div id="terms">
                <div className="d-md-flex  justify-content-between">
                    <div className="term-text">
                    {info && info.Cookiemessage}
                    </div>
                    <div className="accept-btn" onClick={(e) => {
                        e.preventDefault()
                        localStorage.setItem("acceptCookie", "true")
                        setstate(true)
                    }}>
                         Accept
                    </div>
                </div>
            </div>  ))}
        </div>
    )
}
